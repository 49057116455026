// Generated by Framer (b51e01e)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-rQ8CM"

const variantClassNames = {BI9MYYu84: "framer-v-dyx43a"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "BI9MYYu84", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", intrinsicHeight: 920, intrinsicWidth: 1470, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 1840, pixelWidth: 2940, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/JO5OsplBfL6tEx5qUtqioViOMQ.png?scale-down-to=2048", srcSet: "https://framerusercontent.com/images/JO5OsplBfL6tEx5qUtqioViOMQ.png?scale-down-to=512 512w,https://framerusercontent.com/images/JO5OsplBfL6tEx5qUtqioViOMQ.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/JO5OsplBfL6tEx5qUtqioViOMQ.png?scale-down-to=2048 2048w,https://framerusercontent.com/images/JO5OsplBfL6tEx5qUtqioViOMQ.png 2940w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-dyx43a", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"BI9MYYu84"} ref={ref ?? ref1} style={{...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rQ8CM.framer-l1bqug, .framer-rQ8CM .framer-l1bqug { display: block; }", ".framer-rQ8CM.framer-dyx43a { height: 920px; overflow: visible; position: relative; width: 1470px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 920
 * @framerIntrinsicWidth 1470
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerDlq9e5Zi7: React.ComponentType<Props> = withCSS(Component, css, "framer-rQ8CM") as typeof Component;
export default FramerDlq9e5Zi7;

FramerDlq9e5Zi7.displayName = "Preview_Angled";

FramerDlq9e5Zi7.defaultProps = {height: 920, width: 1470};

addFonts(FramerDlq9e5Zi7, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})